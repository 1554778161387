import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import "react-accessible-accordion/dist/fancy-example.css";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image = require("../../../assets/img/blogs/hsmg_blog_header.png");
const section_1 = require("../../../assets/img/blogs/hsmg_blog_image_2.png");
const section_2 = require("../../../assets/img/blogs/hsmg_blog_image_1.png");
const section_3 = require("../../../assets/img/blogs/hsmg_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/hsmg_blog_image_4.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="HR Service Management - Ultimate Guide 2023"
        description="Discover how HR service management can help you improve employee performance by streamlining processes and automating workflows."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt hsmg_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : "float-left"
                }`}
              >
                <h1
                  className={`mb-2 color-white ${
                    isMobile
                      ? "font-section-sub-header-blog-title"
                      : "font-section-sub-header-blog-title"
                  }`}
                >
                  A Complete Guide - 2023
                </h1>
                <h2
                  className={` color-white  ${
                    isMobile
                      ? "font-page-header-home-blog"
                      : "font-page-header-home-blog"
                  }`}
                >
                  HR Service Management
                </h2>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is HR service management?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How does the HR service delivery model look?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Why does your business need a unified HR service delivery?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What is the importance of HR service management?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. What are the use cases of HR service management?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. What are the benefits of HR service management delivery?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. What is the best practice for implementing HR service
                  management?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. How can you enhance your HR service management with a
                  conversational AI platform?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section9"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section9"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section9")}
                >
                  9. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Human resource management services aim at helping HR
                professionals deliver 360-degree support to their employees and
                expedite organizational growth. However, HR operations have
                always been challenging. Over the last two years, there has been
                so much uncertainty, 一 a seismic shift in pandemic-hit work
                culture, and a tight labor market, adding to HR challenges.
                Surprisingly, all of these factors add to employee experience
                and organizational growth. The inability to cope with employee
                retention in the hybrid model also directly impacts the HR
                experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                In{" "}
                <a href="https://www.sage.com/en-gb/blog/changing-face-hr-2024/">
                  Sage’s new annual report, “The Changing Face of HR in
                  2024”,&nbsp;
                </a>
                HR professionals gave some mixed responses, which indicate an
                unpredictable HR stance in the current landscape.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Here’s what the survey report suggests:
                </span>
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  81% of HR professionals are struggling with the day-to-day
                  human resource operations
                </li>
                <li className="font-section-normal-text-testimonials">
                  62% of the participants revealed that they want to leave their
                  professional
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                In most cases, the scenario looks hostile for human resource
                teams because their existing processes are repetitive, manual,
                and error-prone. It is widespread in organizations, especially
                in small to mid-sized companies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Fully managed{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  HR service management
                </a>{" "}
                thus is relatively convenient for most HR leaders as they aim to
                simplify HR operations and boost employee satisfaction and
                experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to know more about HR service management, our guide
                helps you get a full view of this ecosystem. Let’s get started.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is HR service management?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                HR service management takes human resource operations beyond
                traditional methods to the{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  self-service and AI-automated workflow-based landscape.{" "}
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                An automated HR service delivery lessens the manual dependency
                for several processes executed through emails, in-person, or
                phone calls. Instead, it automates and streamlines every
                process, including,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  employee onboarding
                </li>
                <li className="font-section-normal-text-testimonials">
                  document management
                </li>
                <li className="font-section-normal-text-testimonials">
                  employee request and query management, etc
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Human resource service delivery is more of a self-service-led
                architecture to diminish the most repetitive work processes,
                whereas it also ensures to combine of the different aspects of
                human resource operations into one unified platform to offer
                on-demand access to service requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With an HR service delivery tool, HR can easily integrate with a
                cross-functional team such as IT, facilities, finance, payroll,
                and many other areas to simplify employee processes and give
                them an outstanding experience through near real-time service
                delivery.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does the HR service delivery model look?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Today’s HR service delivery model differs from the traditional
                HRSM management system, which hinges upon paperwork and
                face-to-face interaction even in normal cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR service delivery model is SaaS-based today for most
                organizations, meaning it dwells in the cloud and brings
                modernization to every step of HR operations through
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  app workflow automation and chatbot integration
                </a>{" "}
                in the business comms channels.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Here are various parts of an HR service delivery model:{" "}
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The HR service delivery model has multiple formats, from the
                traditional to the most sophisticated one.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Traditional hr service delivery model
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR generalists take center stage to help employees solve their
                queries and requests. This is an ecosystem where the traditional
                methods do the rounds. Employees need to interact directly
                face-to-face with HR teams and submit requests on paper to get
                things done.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Self-service hr delivery model
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Just as customers love to solve their answers DIY way, employees
                also want to solve their requests without human intervention.
                This allows them to reduce wait time and return to work
                immediately.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                To help them achieve this objective,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-chatbot-benefits-business">
                  FAQ-based chatbots that sit in the communication channels like
                  Teams and Slack are convenient.
                </a>{" "}
                Also, automated app workflows tie back to user queries and
                escalate issues to the next level when it needs expert help.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="self-service FAQ-based HR delivery model"
              />
              <h3 className="font-section-sub-header-small-home ">
                Shared services hr delivery model
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is an HR model where various HR roles share
                responsibilities among themselves. It encompasses HR roles,
                including HR specialists, generalists, and CHROs.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Tiered -service hr delivery model
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                This HR architecture combines all the above models, such as
                traditional,{" "}
                <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                  self-service,
                </a>{" "}
                and shared service.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Let’s see how it works:{" "}
                </span>
              </p>
              <img src={section_2} className="blog_image_top_bt" />
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why does your business need a unified HR service delivery?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                We know that HR plays a vital role in bridging the gap between
                the workforce and the organization.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Most often than not, HR service management processes in most
                organizations are still manual and traditional.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Major HR processes rely on word documents, excel sheets, or
                phone calls, which add to process inefficiencies and delays in
                the work progress.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Some of the pressing concerns for the traditional HR operating
                model -
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Disparate tools across cross-functional teams
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employees usually need answers across payroll, finance, IT,
                facilities, and other operations. Unfortunately, organizations
                use disparate tools for cross-functional teams, offering no
                centralized information source. This results in frustration and
                confusion for employees as they need to shuffle through too many
                tools.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Lack of hybrid infrastructure
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Remote work is nowhere to go. As per Owl Labs report,{" "}
                <a href="https://www.forbes.com/sites/bryanrobinson/2022/02/01/remote-work-is-here-to-stay-and-will-increase-into-2023-experts-say/?sh=1ceb25d20a6c">
                  90% of the participants surveyed confirmed an increase in
                  productivity while working remotely as opposed to when they
                  worked from the office.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Many big companies are announcing work-from-home policies or
                hybrid mode due to remote work encouraging employee well-being
                and mental health.
              </p>
              <BlogCta
                ContentCta="Transform HR Service Delivery with Conversational AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                However, not conforming to the current workplace evolution may
                push HR leaders to face great attrition. The downside is that
                many organizations lack the budget or infrastructure to support
                hybrid work.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Complex HR processes
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                As companies scale, complexities compound for HR processes. The
                inability to adjust to the growing needs of HR functions
                restricts end-to-end interactions between human resources and
                employees. It is mandatory for most HR service management tools
                to be flexible and scalable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that said, Mckinsey's study ‘Reimagining HR: Insights from
                People Leaders’ predicted significant changes to the existing HR
                operating model due to the pandemic and will likely undergo{" "}
                <a href="https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/reimagining-hr-insights-from-people-leaders">
                  a massive transformation over the next two to three years.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It stresses the digitalization of the HR process by automating
                traditional administrative tasks and applying mobile-based
                self-service functionalities.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                  Enabling an automated HR operating model
                </a>{" "}
                or unified HR service management is easier for leaders to gather
                employee data and make informed decisions.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is the importance of HR service management?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                HR service delivery management is important because
                organizations can efficiently build harmony between their people
                and organizations.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                By ensuring that employees have all the right tools and
                resources to access critical information and work steadily, HR
                professionals help organizations meet business expectations and
                stay compliant.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                A human resource management tool allows the HR teams to meet
                employee demand and keep them satisfied. The same tool can be
                useful in helping nurture the hybrid work setting by enabling
                organizations to derive employee data and improve weak areas to
                foster employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the use cases of HR service management?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                The modern-day HR service management tool keeps everything under
                the fold to transform organizational perspective towards
                employee management and experience. Let’s break down the areas
                where human resource management delivery can benefit an
                organization.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="an HR service management delivery improves the experience for employees, and HR professionals"
              />
              <h3 className="font-section-sub-header-small-home ">
                Employee experience management
              </h3>
              <h4 className="font-section-normal-text-medium">
                Encourage work from anywhere and anytime.
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                With a{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                  surging digital transformation at the workplace,
                </a>
                employees must seek flexibility in doing work whenever they
                want, no matter where they are. This is what remote work looks
                like. An hr service delivery tool facilitates organizational
                goals by making information readily available around the clock,
                which enhances employee productivity and efficiency.
              </p>
              <h4 className="font-section-normal-text-medium">
                Offer Multi-modal employee support.
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                It is easier to reduce the extra effort for your employees as
                they need to look for information in a cross-functional
                department. Beyond those typical HR queries, employees may need
                information about other operational areas.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                An HR service management tool helps you create end-to-end
                employee-centric digital workflows that tie various departments,
                i.e., IT, finance, and facilities, into one platform, making it
                easy to harness information and remove employee friction.
              </p>
              <h4 className="font-section-normal-text-medium">
                Enable a steady flow of collaborations and communications
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                A hybrid workforce can easily scale with the right tool to
                communicate and collaborate. With the ability to collect
                information from{" "}
                <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                  a self-service portal
                </a>{" "}
                embedded in an HR service management delivery tool through a
                knowledge base or FAQ-based hub, employees are empowered to
                unleash enterprise-wide productivity.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Alongside the existing{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-virtual-assistant-guide">
                  virtual assistant embedded in an hr service delivery,
                </a>{" "}
                collaboration tools like MS teams and Slack offer comprehensive
                support to facilitate employee engagement and auto-resolutions
                of requests.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="HR chatbot integration with MS Teams to facilitate robust employee communications and collaborations"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  {" "}
                  A virtual assistant from Workativ offers out-of-the-box
                  integrations with a custom conversational AI platform
                </a>{" "}
                inside your business comms channels like Slack and Microsoft to
                lessen multiple logins and logouts and enhance the communication
                experience without leaving the channels they are currently
                using. It means your employee no longer needs to be available on
                enterprise systems. Instead, they can easily fetch information
                through mobile apps.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                HR experience management
              </h3>
              <h4 className="font-section-normal-text-medium">
                Enterprise-wide process development
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                An human resource service delivery brings every key HR role to
                the same platform and improves visibility across work processes.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  CHRO can build workplace strategies to improve employee
                  performance and ensure superior employee experience
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Vice president of HR can monitor HR processes across various
                  locations and implement uniformity in policies to improve
                  employee engagement and drive retention
                </li>
                <li className="font-section-normal-text-testimonials">
                  Head of HR operations can leverage employee data to deliver
                  end-to-end human resource services
                </li>
                <li className="font-section-normal-text-testimonials">
                  People head can derive data across cross-functional teams and
                  foster process efficiency to increase employee productivity
                  and make them feel valued
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium">
                Self-service portal for case management and beyond
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                As an HR service management tool features a self-service portal,
                it lessens a huge chunk of repetitive work by either automating
                them or allowing employees to auto-resolve them. Using a
                self-service portal, an HR team can solve a number of requests,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Allow on-demand requests to be solved automatically using
                  knowledge base or FAQ-based resources
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/agent-handover-chatbot">
                    Manage workflows to route the special case to the right
                    person
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  Gain control over service policies to eliminate SLA violation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Manage sensitive case through a confidential service desk by
                  defining rules such as who can view it, who can monitor it,
                  etc
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium">
                Improved knowledge management
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                It is simple to maintain a knowledge base with updated
                information relevant to specific employee role. Using an HR
                service management delivery tool, HR leaders can look at
                analytics to analyze request variations and prepare a knowledge
                base with information that helps employees solve their issues by
                themselves.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                For say, if your employees come up with issues related to the
                leave policies for a vacation, you can design your knowledge
                base according to this specific keyword and reduce the number of
                ticket volumes.
              </p>
              <h4 className="font-section-normal-text-medium">
                Onboarding/offboarding management
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                A service management tool for HR makes onboarding and
                offboarding efficient and productive by automating the most
                repetitive tasks, such as,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Scheduling an invite for welcome day interactions with teams
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Sending a ‘wish’ card to welcome a new hire
                </li>
                <li className="font-section-normal-text-testimonials">
                  Provisioning workplace assets and credentials
                </li>
                <li className="font-section-normal-text-testimonials">
                  Scheduling offboarding through workflows that ask for
                  submission of workplace credentials, apps, and other resources
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                By removing extra activities from the onboarding and offboarding
                journeys, companies are better off to retain the best talent and
                increase headcounts.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the benefits of HR service management delivery?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                An organization can tap into potential benefits from an HR
                service delivery management tool in the following way:
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Better employee experience
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Better employee experience An HR service delivery platform
                offers consumer-grade service for the employees. With 24x7
                information at their fingertips, employees are more empowered to
                perform a task. As a result, organizations can expect fewer
                chances of attrition. On the other hand, the retention rate will
                dramatically increase.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Increased HR efficiency
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Powerful case management and knowledge management allow HR
                professionals to reduce the time for repetitive tasks through a
                self-service model. On the other hand, it is fast to distribute
                workloads across various HR roles, resulting in more time and
                energy to focus on critical tasks and help employees to solve
                their queries in no time.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Automated workflows
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                No matter if a request is complex or simple, every task can find
                solutions. Organizations can easily route tasks to the right
                team and quickly solve problems by creating workflows based on
                the work history.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Continuous service delivery improvement
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A periodic review of the work progress can reveal where you are
                lacking. An HR service management tool offers insights into key
                performance indicators to make data-driven decisions.{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                  Data analytics is key to measuring the effectiveness of your
                  business goals
                </a>{" "}
                and understanding how efficiently you encourage workplace
                progress.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Cost-effectiveness for HR operations
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                With the self-service functionalities, HR processes decrease in
                volume as there are fewer volumes of repetitive tasks. With
                reduced HR involvement in request management, organizations can
                save time and effort, eventually saving costs while improving
                performance.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What is the best practice for implementing HR service
                management?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                So far, an HR service management delivery platform is critical
                for any modern organization to transform workplace culture and
                expedite process efficiency across human resource operations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                However, unless you have a strategy to optimize a human resource
                service management platform, you are less likely to drive
                business goals and employee experience.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Follow best practices for a modern HR operating model that
                encompasses,
              </p>
              <h3 className="font-section-sub-header-small-home ">
                User-friendliness
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                No matter what vendor you choose for an HR service management,
                it must be intuitive, and it must foster ease of adoption for
                your users across every department.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                24x7 availability
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Self-service functionality helps retrieve relevant information
                to carry the work forward. However, in many instances, chances
                are that employees need live interactions with agents. Ensure
                your platform offers total contextual awareness of the previous
                conversation as it escalates the matter to the right person.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Customizability
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                As digital workflows vary by unique use cases of a particular
                company, ensure your platform offers extendable customization
                flexibility to design your conditions and logic. This helps take
                control over your workflows and support your employees with
                diversified HR resources and information.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Scalability
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Make sure your platform scales as you grow. If it does not
                support scalability, you will end up having a broken HR system
                that will add to employee friction and process inefficiencies.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How can you enhance your HR service management with a
                conversational AI platform?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Just as you observe the best practices to deploy your HR service
                management, it is sometimes appropriate to say that you are not
                getting enough from your investment.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Let us highlight some areas of concern that come with every HR
                service management platform SMBs or enterprises tend to adopt.
              </p>

              <h3 className="font-section-sub-header-small-home ">
                User-friendliness
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Generally, every HR service management platform encompasses a
                complicated architecture that is difficult to adopt for most
                users in a cross-functional team. As a result, it does not
                encourage widespread adoption, making communications and
                collaborations dependent on excel sheets, phones,s or emails.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Workflow automation
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Almost all leading HR service management vendors offer chatbots
                in a basic plan. So, communications can happen within the
                platform. Unfortunately, the basic plan does not allow you to
                create app workflow automations for existing HR tools. To avail
                of this feature, it needs you to upgrade your package, which is
                a blow to your IT budget.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Ticket management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Although enterprise plans provide chatbot support, they can only
                help with ticket management. Other than creating tickets and
                tracking status, a chatbot in an HR service management tool does
                not offer workplace support.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                So, when you need workplace support to help your employees with
                account unlock, reset passwords, and integrations with workflows
                inside your business comms channels, these chatbots lack the
                necessary capabilities.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  A conversational AI platform from Workativ
                </a>{" "}
                helps you overcome the challenges of an HR service management
                platform.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Using integration flexibility, you can embed workflows inside
                the business comms channels where your employees spend the most
                time and streamline HR processes without much effort.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ Assistant provides amenities that allow you to combine
                IT and HR functionalities into one communication platform and
                enhance team performance.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                By creating your unique conversational chatbot, you can reduce
                time to minimize downtime, save time to handle more critical
                tickets, and enable your team to work efficiently.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Leverage Workativ to gain the ability to bring HR service
                management functionalities to conversational channels like{" "}
                <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                  Teams and Slack through enterprise-grade platform
                  integrations.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Some high-level chatbot automation integration includes
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none  font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials  list-style-type-counter">
                  <span>1. </span>

                  <a href="https://workativ.com/conversational-ai-platform/servicenow-chatbot">
                    ServiceNow chatbot with automation
                  </a>
                </li>{" "}
                <li className="font-section-normal-text-testimonials  list-style-type-counter">
                  <span>2. </span>
                  <a href="https://workativ.com/conversational-ai-platform/sap-successfactors-chatbot">
                    SAP Success Factors chatbot with automation
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials  list-style-type-counter">
                  <span>3. </span>
                  <a href="https://workativ.com/conversational-ai-platform/integrations">
                    {" "}
                    Many more
                  </a>
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                The top line benefit of using Workativ Assistant is that it is
                user-friendly. So, your organization does not need an extra
                budget after training and development and also extended time to
                get started with the digital transformation drive.{" "}
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  Just download and get started.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Most large companies are potentially investing in tools and
                processes to embrace digital transformation. As per Harvard
                Business Review,{" "}
                <a href="https://hbr.org/2021/11/the-essential-components-of-digital-transformation">
                  investment in digital transformation may cost companies well
                  over $6.8 trillion by 2023.
                </a>{" "}
                But the concern is that organizations are unlikely to drive any
                clear benefits or gains from their investments.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The probable reason could be a misconception about the
                transformation agenda. Even if it is an AI product, just
                bringing a tool does not drive productivity gains and efficiency
                unless people and processes maximize these tools.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Based on the opinion of Standford’s Erik Brynjolfsson, companies
                are unable to fully use new technologies and tools because of
                <a href="https://www.nber.org/system/files/working_papers/w24001/w24001.pdf">
                  the lack of initiatives to invest in reskilling and upskilling
                  their employees.
                </a>
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                From this viewpoint, just an HR service management cannot alone
                drive digital transformation, owing to its complicated
                architecture, which is tough to nail quickly. To overcome this
                challenge and save on budget after reskilling and upskilling,
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  a conversational AI platform
                </a>{" "}
                can easily complement your digital transformation initiatives.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                The reason is that a conversational AI platform enables you to
                build a human-like chatbot to interact with your HR service
                management tool and bring all functionalities directly to your
                conversational channels like Slack or Teams. It harmonizes with
                your employee personas and allows them to adapt easily to a new
                ecosystem.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                That too without creating a new agenda for employee training and
                development and allocating a separate budget!
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-2">
                Want to know how conversational AI can help you nurture a
                powerful HR service delivery project?
                <a href="https://workativ.com/contact-us">
                  {" "}
                  Schedule a call with Workative experts today.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is HR service management?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How does the HR service delivery model look?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Why does your business need a unified HR service
                    delivery?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What is the importance of HR service management?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. What are the use cases of HR service management?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. What are the benefits of HR service management delivery?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. What is the best practice for implementing HR service
                    management?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. How can you enhance your HR service management with a
                    conversational AI platform?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section9"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section9"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section9")}
                  >
                    9. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Human resource management services aim at helping HR
                professionals deliver 360-degree support to their employees and
                expedite organizational growth. However, HR operations have
                always been challenging. Over the last two years, there has been
                so much uncertainty, 一 a seismic shift in pandemic-hit work
                culture, and a tight labor market, adding to HR challenges.
                Surprisingly, all of these factors add to employee experience
                and organizational growth. The inability to cope with employee
                retention in the hybrid model also directly impacts the HR
                experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                In{" "}
                <a href="https://www.sage.com/en-gb/blog/changing-face-hr-2024/">
                  Sage’s new annual report, “The Changing Face of HR in
                  2024”,&nbsp;
                </a>
                HR professionals gave some mixed responses, which indicate an
                unpredictable HR stance in the current landscape.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Here’s what the survey report suggests:
                </span>
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  81% of HR professionals are struggling with the day-to-day
                  human resource operations
                </li>
                <li className="font-section-normal-text-testimonials">
                  62% of the participants revealed that they want to leave their
                  professional
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                In most cases, the scenario looks hostile for human resource
                teams because their existing processes are repetitive, manual,
                and error-prone. It is widespread in organizations, especially
                in small to mid-sized companies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Fully managed{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  HR service management
                </a>{" "}
                thus is relatively convenient for most HR leaders as they aim to
                simplify HR operations and boost employee satisfaction and
                experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to know more about HR service management, our guide
                helps you get a full view of this ecosystem. Let’s get started.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is HR service management?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                HR service management takes human resource operations beyond
                traditional methods to the{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  self-service and AI-automated workflow-based landscape.{" "}
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                An automated HR service delivery lessens the manual dependency
                for several processes executed through emails, in-person, or
                phone calls. Instead, it automates and streamlines every
                process, including,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  employee onboarding
                </li>
                <li className="font-section-normal-text-testimonials">
                  document management
                </li>
                <li className="font-section-normal-text-testimonials">
                  employee request and query management, etc
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Human resource service delivery is more of a self-service-led
                architecture to diminish the most repetitive work processes,
                whereas it also ensures to combine of the different aspects of
                human resource operations into one unified platform to offer
                on-demand access to service requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With an HR service delivery tool, HR can easily integrate with a
                cross-functional team such as IT, facilities, finance, payroll,
                and many other areas to simplify employee processes and give
                them an outstanding experience through near real-time service
                delivery.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does the HR service delivery model look?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Today’s HR service delivery model differs from the traditional
                HRSM management system, which hinges upon paperwork and
                face-to-face interaction even in normal cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR service delivery model is SaaS-based today for most
                organizations, meaning it dwells in the cloud and brings
                modernization to every step of HR operations through
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  app workflow automation and chatbot integration
                </a>{" "}
                in the business comms channels.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Here are various parts of an HR service delivery model:{" "}
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The HR service delivery model has multiple formats, from the
                traditional to the most sophisticated one.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Traditional hr service delivery model
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR generalists take center stage to help employees solve their
                queries and requests. This is an ecosystem where the traditional
                methods do the rounds. Employees need to interact directly
                face-to-face with HR teams and submit requests on paper to get
                things done.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Self-service hr delivery model
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Just as customers love to solve their answers DIY way, employees
                also want to solve their requests without human intervention.
                This allows them to reduce wait time and return to work
                immediately.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                To help them achieve this objective,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-chatbot-benefits-business">
                  FAQ-based chatbots that sit in the communication channels like
                  Teams and Slack are convenient.
                </a>{" "}
                Also, automated app workflows tie back to user queries and
                escalate issues to the next level when it needs expert help.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="self-service FAQ-based HR delivery model"
              />
              <h3 className="font-section-sub-header-small-home ">
                Shared services hr delivery model
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is an HR model where various HR roles share
                responsibilities among themselves. It encompasses HR roles,
                including HR specialists, generalists, and CHROs.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Tiered -service hr delivery model
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                This HR architecture combines all the above models, such as
                traditional,{" "}
                <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                  self-service,
                </a>{" "}
                and shared service.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Let’s see how it works:{" "}
                </span>
              </p>
              <img src={section_2} className="blog_image_top_bt" />
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why does your business need a unified HR service delivery?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                We know that HR plays a vital role in bridging the gap between
                the workforce and the organization.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Most often than not, HR service management processes in most
                organizations are still manual and traditional.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Major HR processes rely on word documents, excel sheets, or
                phone calls, which add to process inefficiencies and delays in
                the work progress.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Some of the pressing concerns for the traditional HR operating
                model -
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Disparate tools across cross-functional teams
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employees usually need answers across payroll, finance, IT,
                facilities, and other operations. Unfortunately, organizations
                use disparate tools for cross-functional teams, offering no
                centralized information source. This results in frustration and
                confusion for employees as they need to shuffle through too many
                tools.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Lack of hybrid infrastructure
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Remote work is nowhere to go. As per Owl Labs report,{" "}
                <a href="https://www.forbes.com/sites/bryanrobinson/2022/02/01/remote-work-is-here-to-stay-and-will-increase-into-2023-experts-say/?sh=1ceb25d20a6c">
                  90% of the participants surveyed confirmed an increase in
                  productivity while working remotely as opposed to when they
                  worked from the office.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Many big companies are announcing work-from-home policies or
                hybrid mode due to remote work encouraging employee well-being
                and mental health.
              </p>
              <BlogCta
                ContentCta="Transform HR Service Delivery with Conversational AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                However, not conforming to the current workplace evolution may
                push HR leaders to face great attrition. The downside is that
                many organizations lack the budget or infrastructure to support
                hybrid work.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Complex HR processes
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                As companies scale, complexities compound for HR processes. The
                inability to adjust to the growing needs of HR functions
                restricts end-to-end interactions between human resources and
                employees. It is mandatory for most HR service management tools
                to be flexible and scalable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that said, Mckinsey's study ‘Reimagining HR: Insights from
                People Leaders’ predicted significant changes to the existing HR
                operating model due to the pandemic and will likely undergo{" "}
                <a href="https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/reimagining-hr-insights-from-people-leaders">
                  a massive transformation over the next two to three years.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It stresses the digitalization of the HR process by automating
                traditional administrative tasks and applying mobile-based
                self-service functionalities.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                  Enabling an automated HR operating model
                </a>{" "}
                or unified HR service management is easier for leaders to gather
                employee data and make informed decisions.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is the importance of HR service management?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                HR service delivery management is important because
                organizations can efficiently build harmony between their people
                and organizations.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                By ensuring that employees have all the right tools and
                resources to access critical information and work steadily, HR
                professionals help organizations meet business expectations and
                stay compliant.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                A human resource management tool allows the HR teams to meet
                employee demand and keep them satisfied. The same tool can be
                useful in helping nurture the hybrid work setting by enabling
                organizations to derive employee data and improve weak areas to
                foster employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the use cases of HR service management?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                The modern-day HR service management tool keeps everything under
                the fold to transform organizational perspective towards
                employee management and experience. Let’s break down the areas
                where human resource management delivery can benefit an
                organization.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="an HR service management delivery improves the experience for employees, and HR professionals"
              />
              <h3 className="font-section-sub-header-small-home ">
                Employee experience management
              </h3>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Encourage work from anywhere and anytime.
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                With a{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                  surging digital transformation at the workplace,
                </a>
                employees must seek flexibility in doing work whenever they
                want, no matter where they are. This is what remote work looks
                like. An hr service delivery tool facilitates organizational
                goals by making information readily available around the clock,
                which enhances employee productivity and efficiency.
              </p>
              <h4 className="font-section-normal-text-medium">
                Offer Multi-modal employee support.
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                It is easier to reduce the extra effort for your employees as
                they need to look for information in a cross-functional
                department. Beyond those typical HR queries, employees may need
                information about other operational areas.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                An HR service management tool helps you create end-to-end
                employee-centric digital workflows that tie various departments,
                i.e., IT, finance, and facilities, into one platform, making it
                easy to harness information and remove employee friction.
              </p>
              <h4 className="font-section-normal-text-medium">
                Enable a steady flow of collaborations and communications
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                A hybrid workforce can easily scale with the right tool to
                communicate and collaborate. With the ability to collect
                information from{" "}
                <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                  a self-service portal
                </a>{" "}
                embedded in an HR service management delivery tool through a
                knowledge base or FAQ-based hub, employees are empowered to
                unleash enterprise-wide productivity.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Alongside the existing{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-virtual-assistant-guide">
                  virtual assistant embedded in an hr service delivery,
                </a>{" "}
                collaboration tools like MS teams and Slack offer comprehensive
                support to facilitate employee engagement and auto-resolutions
                of requests.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="HR chatbot integration with MS Teams to facilitate robust employee communications and collaborations"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  {" "}
                  A virtual assistant from Workativ offers out-of-the-box
                  integrations with a custom conversational AI platform
                </a>{" "}
                inside your business comms channels like Slack and Microsoft to
                lessen multiple logins and logouts and enhance the communication
                experience without leaving the channels they are currently
                using. It means your employee no longer needs to be available on
                enterprise systems. Instead, they can easily fetch information
                through mobile apps.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                HR experience management
              </h3>
              <h4 className="font-section-normal-text-medium">
                Enterprise-wide process development
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                An human resource service delivery brings every key HR role to
                the same platform and improves visibility across work processes.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  CHRO can build workplace strategies to improve employee
                  performance and ensure superior employee experience
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Vice president of HR can monitor HR processes across various
                  locations and implement uniformity in policies to improve
                  employee engagement and drive retention
                </li>
                <li className="font-section-normal-text-testimonials">
                  Head of HR operations can leverage employee data to deliver
                  end-to-end human resource services
                </li>
                <li className="font-section-normal-text-testimonials">
                  People head can derive data across cross-functional teams and
                  foster process efficiency to increase employee productivity
                  and make them feel valued
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium">
                Self-service portal for case management and beyond
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                As an HR service management tool features a self-service portal,
                it lessens a huge chunk of repetitive work by either automating
                them or allowing employees to auto-resolve them. Using a
                self-service portal, an HR team can solve a number of requests,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Allow on-demand requests to be solved automatically using
                  knowledge base or FAQ-based resources
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/agent-handover-chatbot">
                    Manage workflows to route the special case to the right
                    person
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  Gain control over service policies to eliminate SLA violation
                </li>
                <li className="font-section-normal-text-testimonials">
                  Manage sensitive case through a confidential service desk by
                  defining rules such as who can view it, who can monitor it,
                  etc
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium">
                Improved knowledge management
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                It is simple to maintain a knowledge base with updated
                information relevant to specific employee role. Using an HR
                service management delivery tool, HR leaders can look at
                analytics to analyze request variations and prepare a knowledge
                base with information that helps employees solve their issues by
                themselves.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                For say, if your employees come up with issues related to the
                leave policies for a vacation, you can design your knowledge
                base according to this specific keyword and reduce the number of
                ticket volumes.
              </p>
              <h4 className="font-section-normal-text-medium">
                Onboarding/offboarding management
              </h4>
              <p class="font-section-normal-text-testimonials line-height-18">
                A service management tool for HR makes onboarding and
                offboarding efficient and productive by automating the most
                repetitive tasks, such as,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Scheduling an invite for welcome day interactions with teams
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  Sending a ‘wish’ card to welcome a new hire
                </li>
                <li className="font-section-normal-text-testimonials">
                  Provisioning workplace assets and credentials
                </li>
                <li className="font-section-normal-text-testimonials">
                  Scheduling offboarding through workflows that ask for
                  submission of workplace credentials, apps, and other resources
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                By removing extra activities from the onboarding and offboarding
                journeys, companies are better off to retain the best talent and
                increase headcounts.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the benefits of HR service management delivery?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                An organization can tap into potential benefits from an HR
                service delivery management tool in the following way:
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Better employee experience
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Better employee experience An HR service delivery platform
                offers consumer-grade service for the employees. With 24x7
                information at their fingertips, employees are more empowered to
                perform a task. As a result, organizations can expect fewer
                chances of attrition. On the other hand, the retention rate will
                dramatically increase.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Increased HR efficiency
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Powerful case management and knowledge management allow HR
                professionals to reduce the time for repetitive tasks through a
                self-service model. On the other hand, it is fast to distribute
                workloads across various HR roles, resulting in more time and
                energy to focus on critical tasks and help employees to solve
                their queries in no time.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Automated workflows
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                No matter if a request is complex or simple, every task can find
                solutions. Organizations can easily route tasks to the right
                team and quickly solve problems by creating workflows based on
                the work history.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Continuous service delivery improvement
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A periodic review of the work progress can reveal where you are
                lacking. An HR service management tool offers insights into key
                performance indicators to make data-driven decisions.{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                  Data analytics is key to measuring the effectiveness of your
                  business goals
                </a>{" "}
                and understanding how efficiently you encourage workplace
                progress.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Cost-effectiveness for HR operations
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                With the self-service functionalities, HR processes decrease in
                volume as there are fewer volumes of repetitive tasks. With
                reduced HR involvement in request management, organizations can
                save time and effort, eventually saving costs while improving
                performance.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What is the best practice for implementing HR service
                management?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                So far, an HR service management delivery platform is critical
                for any modern organization to transform workplace culture and
                expedite process efficiency across human resource operations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                However, unless you have a strategy to optimize a human resource
                service management platform, you are less likely to drive
                business goals and employee experience.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Follow best practices for a modern HR operating model that
                encompasses,
              </p>
              <h3 className="font-section-sub-header-small-home ">
                User-friendliness
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                No matter what vendor you choose for an HR service management,
                it must be intuitive, and it must foster ease of adoption for
                your users across every department.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                24x7 availability
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Self-service functionality helps retrieve relevant information
                to carry the work forward. However, in many instances, chances
                are that employees need live interactions with agents. Ensure
                your platform offers total contextual awareness of the previous
                conversation as it escalates the matter to the right person.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Customizability
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                As digital workflows vary by unique use cases of a particular
                company, ensure your platform offers extendable customization
                flexibility to design your conditions and logic. This helps take
                control over your workflows and support your employees with
                diversified HR resources and information.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Scalability
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Make sure your platform scales as you grow. If it does not
                support scalability, you will end up having a broken HR system
                that will add to employee friction and process inefficiencies.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How can you enhance your HR service management with a
                conversational AI platform?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Just as you observe the best practices to deploy your HR service
                management, it is sometimes appropriate to say that you are not
                getting enough from your investment.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Let us highlight some areas of concern that come with every HR
                service management platform SMBs or enterprises tend to adopt.
              </p>

              <h3 className="font-section-sub-header-small-home ">
                User-friendliness
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Generally, every HR service management platform encompasses a
                complicated architecture that is difficult to adopt for most
                users in a cross-functional team. As a result, it does not
                encourage widespread adoption, making communications and
                collaborations dependent on excel sheets, phones,s or emails.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Workflow automation
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Almost all leading HR service management vendors offer chatbots
                in a basic plan. So, communications can happen within the
                platform. Unfortunately, the basic plan does not allow you to
                create app workflow automations for existing HR tools. To avail
                of this feature, it needs you to upgrade your package, which is
                a blow to your IT budget.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Ticket management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Although enterprise plans provide chatbot support, they can only
                help with ticket management. Other than creating tickets and
                tracking status, a chatbot in an HR service management tool does
                not offer workplace support.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                So, when you need workplace support to help your employees with
                account unlock, reset passwords, and integrations with workflows
                inside your business comms channels, these chatbots lack the
                necessary capabilities.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  A conversational AI platform from Workativ
                </a>{" "}
                helps you overcome the challenges of an HR service management
                platform.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Using integration flexibility, you can embed workflows inside
                the business comms channels where your employees spend the most
                time and streamline HR processes without much effort.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ Assistant provides amenities that allow you to combine
                IT and HR functionalities into one communication platform and
                enhance team performance.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                By creating your unique conversational chatbot, you can reduce
                time to minimize downtime, save time to handle more critical
                tickets, and enable your team to work efficiently.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Leverage Workativ to gain the ability to bring HR service
                management functionalities to conversational channels like{" "}
                <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                  Teams and Slack through enterprise-grade platform
                  integrations.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Some high-level chatbot automation integration includes
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none  font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials  list-style-type-counter">
                  <span>1. </span>

                  <a href="https://workativ.com/conversational-ai-platform/servicenow-chatbot">
                    ServiceNow chatbot with automation
                  </a>
                </li>{" "}
                <li className="font-section-normal-text-testimonials  list-style-type-counter">
                  <span>2. </span>
                  <a href="https://workativ.com/conversational-ai-platform/sap-successfactors-chatbot">
                    SAP Success Factors chatbot with automation
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials  list-style-type-counter">
                  <span>3. </span>
                  <a href="https://workativ.com/conversational-ai-platform/integrations">
                    {" "}
                    Many more
                  </a>
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                The top line benefit of using Workativ Assistant is that it is
                user-friendly. So, your organization does not need an extra
                budget after training and development and also extended time to
                get started with the digital transformation drive.{" "}
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  Just download and get started.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Most large companies are potentially investing in tools and
                processes to embrace digital transformation. As per Harvard
                Business Review,{" "}
                <a href="https://hbr.org/2021/11/the-essential-components-of-digital-transformation">
                  investment in digital transformation may cost companies well
                  over $6.8 trillion by 2023.
                </a>{" "}
                But the concern is that organizations are unlikely to drive any
                clear benefits or gains from their investments.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The probable reason could be a misconception about the
                transformation agenda. Even if it is an AI product, just
                bringing a tool does not drive productivity gains and efficiency
                unless people and processes maximize these tools.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Based on the opinion of Standford’s Erik Brynjolfsson, companies
                are unable to fully use new technologies and tools because of
                <a href="https://www.nber.org/system/files/working_papers/w24001/w24001.pdf">
                  the lack of initiatives to invest in reskilling and upskilling
                  their employees.
                </a>
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                From this viewpoint, just an HR service management cannot alone
                drive digital transformation, owing to its complicated
                architecture, which is tough to nail quickly. To overcome this
                challenge and save on budget after reskilling and upskilling,
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  a conversational AI platform
                </a>{" "}
                can easily complement your digital transformation initiatives.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                The reason is that a conversational AI platform enables you to
                build a human-like chatbot to interact with your HR service
                management tool and bring all functionalities directly to your
                conversational channels like Slack or Teams. It harmonizes with
                your employee personas and allows them to adapt easily to a new
                ecosystem.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                That too without creating a new agenda for employee training and
                development and allocating a separate budget!
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-2">
                Want to know how conversational AI can help you nurture a
                powerful HR service delivery project?
                <a href="https://workativ.com/contact-us">
                  {" "}
                  Schedule a call with Workative experts today.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              Service Desk Chatbot Automation: A Complete Guide
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT: Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
